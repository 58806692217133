import styled, { css } from 'styled-components'
import { useState, useRef } from 'react'
import { useHover } from 'usehooks-ts'
import { motion, AnimatePresence } from 'framer-motion'

import Image from 'next/image'
import Link from 'next/link'
import { HiChevronDown } from 'react-icons/hi'
import { GoPlus } from 'react-icons/go'
import HamMenuButton from '../ham-menu-button/ham-menu-button.component'

import logoImage from '../../../public/assets/ivetars-logo-2.svg'
import { socials } from '@/config/socials.config'

import { ICategory } from '@/types/category.types'
export interface IProps {
  categories: ICategory[]
}

export default function Header({ categories }: IProps) {
  const [state, setState] = useState({
    isMenuOpen: false,
    isCategoriesDropdownOpen: false,
    isSocialDropdownOpen: false,
  })

  const aboutRefButton = useRef<HTMLButtonElement>(null)
  const socialRefButton = useRef<HTMLButtonElement>(null)
  const isCategoriesButtonHovered = useHover(aboutRefButton)
  const isSocialButtonHovered = useHover(socialRefButton)

  const NAV_ITEMS = [
    {
      label: 'Úvod',
      href: '/blog/ako-zit-s-obmedzenymi-nazormi-medzi-udmi-s-obmedzenymi-nazormi',
    },
    {
      label: 'O mne',
      href: '/o-mne',
    },
    {
      ref: aboutRefButton,
      label: 'Kategórie',
      isHovered: isCategoriesButtonHovered,
      isOpen: state.isCategoriesDropdownOpen,
      toggle: () =>
        setState((prevState) => ({
          ...prevState,
          isCategoriesDropdownOpen: !prevState.isCategoriesDropdownOpen,
        })),
      list: categories.map((category) => ({
        label: category.name,
        href: `/kategorie/${category.slug}`,
      })),
    },
    {
      ref: socialRefButton,
      label: 'Socials',
      isHovered: isSocialButtonHovered,
      isOpen: state.isSocialDropdownOpen,
      toggle: () =>
        setState((prevState) => ({
          ...prevState,
          isSocialDropdownOpen: !prevState.isSocialDropdownOpen,
        })),
      list: socials,
    },
    {
      label: 'Kontakt',
      href: '/kontakt',
    },
  ]

  return (
    <>
      <HeaderContainer>
        <Wrapper id="header">
          <Link href="/">
            <LogoFigure>
              <Image src={logoImage} alt="Ivetars blog logo" />
            </LogoFigure>
          </Link>

          <Nav>
            <NavList>
              {NAV_ITEMS.map((item, index) => (
                <NavItem key={index}>
                  {item.href && (
                    <Link href={item.href} passHref>
                      {item.label}
                    </Link>
                  )}
                  {item.list && (
                    <DropdownButton ref={item.ref}>
                      <span>{item.label}</span>
                      <span>
                        <HiChevronDown />
                      </span>
                      <AnimatePresence>
                        {item.isHovered && (
                          <DropdownMenu
                            initial={{
                              opacity: 0,
                              backdropFilter: 'blur(0px)',
                            }}
                            animate={{
                              opacity: 1,
                              backdropFilter: 'blur(20px)',
                            }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3, ease: 'easeIn' }}
                          >
                            <DropdownBlurOverlay />
                            {item.list.map((listItem, index) => (
                              <DropdownMenuItem key={index}>
                                <Link {...listItem} href={listItem.href}>
                                  <span>{listItem.label}</span>
                                </Link>
                              </DropdownMenuItem>
                            ))}
                          </DropdownMenu>
                        )}
                      </AnimatePresence>
                    </DropdownButton>
                  )}
                </NavItem>
              ))}
            </NavList>
          </Nav>
          <HamMenuButton
            isOpen={state.isMenuOpen}
            onClick={() =>
              setState((prevValue) => ({
                ...prevValue,
                isMenuOpen: !prevValue.isMenuOpen,
              }))
            }
          />
        </Wrapper>
      </HeaderContainer>
      <AnimatePresence>
        {state.isMenuOpen && (
          <MobileMenuContainer
            initial={{
              opacity: 0,
              y: -200,
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            exit={{
              opacity: 0,
              y: -200,
            }}
            transition={{
              type: 'spring',
              mass: 1,
              stiffness: 100,
              damping: 15,
            }}
          >
            <MobileNav>
              <MobileNavList>
                {NAV_ITEMS.map((item, index) => (
                  <MobileNavItem key={index}>
                    {item.href && (
                      <div
                        onClick={() =>
                          setState((prevValue) => ({
                            ...prevValue,
                            isMenuOpen: !prevValue.isMenuOpen,
                          }))
                        }
                      >
                        <Link href={item.href}>{item.label}</Link>
                      </div>
                    )}
                    {item.list && (
                      <MobileDropdownButton
                        isOpen={item.isOpen}
                        onClick={() => item.toggle()}
                      >
                        <span>{item.label}</span>
                        <IconSpan
                          isOpen={item.isOpen}
                          animate={
                            item.isOpen ? { rotate: '-45deg' } : { rotate: 0 }
                          }
                          transition={{
                            type: 'spring',
                            mass: 1,
                            stiffness: 256,
                            damping: 24,
                          }}
                        >
                          <GoPlus />
                        </IconSpan>
                      </MobileDropdownButton>
                    )}
                    <AnimatePresence>
                      {item.list && item.isOpen && (
                        <MobileDropdownMenu
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: 'auto' }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{
                            type: 'spring',
                            mass: 1,
                            stiffness: 256,
                            damping: 24,
                          }}
                        >
                          <MobileDropdownList>
                            {item.list.map((listItem, index) => (
                              <MobileDropdownMenuItem
                                key={index}
                                onClick={() =>
                                  setState((prevValue) => ({
                                    ...prevValue,
                                    isMenuOpen: !prevValue.isMenuOpen,
                                  }))
                                }
                              >
                                <Link {...listItem} href={listItem.href}>
                                  <span>{listItem.label}</span>
                                </Link>
                              </MobileDropdownMenuItem>
                            ))}
                          </MobileDropdownList>
                        </MobileDropdownMenu>
                      )}
                    </AnimatePresence>
                  </MobileNavItem>
                ))}
              </MobileNavList>
            </MobileNav>
          </MobileMenuContainer>
        )}
      </AnimatePresence>
    </>
  )
}

const HeaderContainer = styled.div`
  width: 100%;
  position: fixed;
  z-index: 999;
  height: 8rem;

  background-color: ${({ theme }) => theme.colors.primary};
`

const Wrapper = styled.div``

const LogoFigure = styled.figure`
  width: 13rem;

  img {
    width: 100%;
    height: 100%;
  }
`

const Nav = styled.nav`
  display: none;
  visibility: hidden;
  @media all and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
    visibility: visible;
    margin-left: 4rem;
  }
`

const NavList = styled.ul`
  display: flex;
  gap: 3.9rem;
`

const navLinksStyles = css`
  cursor: pointer;
  text-transform: uppercase;
  line-height: 1.8;
  color: ${({ theme }) => theme.fonts.primary};
  transition: all 0.3s ease-in;
  &:hover {
    color: ${({ theme }) => theme.fonts.secondary};
  }
`

const NavItem = styled.li`
  position: relative;
  a {
    ${navLinksStyles};
    font-size: 1.6rem;
  }
`

const DropdownButton = styled.button`
  ${navLinksStyles};
  font-size: 1.6rem;
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const DropdownMenu = styled(motion.div)`
  position: absolute;
  width: 26rem;
  top: 100%;
  right: 0;
  z-index: 100;
  border-radius: 1.2rem;
  overflow: hidden;
`

const DropdownBlurOverlay = styled(motion.div)`
  overflow: hidden;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  z-index: -100;
  background-color: ${({ theme }) => theme.colors.primary};
  backdrop-filter: blur(20px);
`

const DropdownMenuItem = styled(motion.div)`
  position: relative;
  z-index: 101;
  a {
    font-size: 1.4rem;
    padding: 1.4rem 2.4rem;
    display: flex;
    align-items: center;
    gap: 1.7rem;
    color: ${({ theme }) => theme.fonts.primary};
    &:hover {
      color: ${({ theme }) => theme.fonts.secondary};
      background: ${({ theme }) => theme.colors.secondary};
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
  }
`

const MobileMenuContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;

  width: 100%;
  height: 100vh;

  background-color: ${({ theme }) => theme.colors.primary};

  @media all and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
    visibility: hidden;
  }
`

const MobileNav = styled.nav`
  margin-top: 6.4rem;
  padding: 0 3.6rem 8rem;

  height: 100%;
  overflow-y: auto;
`

const MobileNavList = styled.ul``

const MobileNavItem = styled.li`
  a {
    ${navLinksStyles};

    display: block;
    padding: 2rem 0;
    color: ${({ theme }) => theme.fonts.primary};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
  }
`

const MobileDropdownButton = styled.button<{ isOpen: boolean }>`
  ${navLinksStyles};
  background-color: transparent;
  outline: none;
  border: none;
  padding: 2rem 0;
  font-family: inherit;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in;
    font-size: 1.6rem;
    color: ${({ isOpen, theme }) =>
      isOpen ? theme.fonts.secondary : theme.fonts.primary};
  }

  &:hover {
    span {
      color: ${({ theme }) => theme.fonts.secondary};
    }
  }
`

const IconSpan = styled(motion.span)<{ isOpen: boolean }>`
  /* transition: 0.3s; */
  color: ${({ isOpen, theme }) =>
    isOpen ? theme.fonts.secondary : theme.fonts.primary};
`

const MobileDropdownMenu = styled(motion.div)`
  overflow: hidden;
`

const MobileDropdownList = styled.ul`
  margin: 0 0rem;
`

const MobileDropdownMenuItem = styled(motion.li)`
  /* padding: 2rem 0; */

  a {
    ${navLinksStyles};

    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 1.4rem 0.6rem;
    color: ${({ theme }) => theme.fonts.primary};

    &:hover {
      color: ${({ theme }) => theme.fonts.secondary};
      background: ${({ theme }) => theme.colors.secondary};
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
  }
`
