import styled from 'styled-components'

import Image from 'next/image'
import Link from 'next/link'

import logoImage from '../../../public/assets/ivetars-logo-2.svg'
import { socials } from '@/config/socials.config'

import { ICategory } from '@/types/category.types'
export interface IProps {
  categories: ICategory[]
}

const Footer = ({ categories }: IProps) => {
  const list = socials

  const sitemap = [
    {
      label: 'Úvod',
      href: '/blog/ako-zit-s-obmedzenymi-nazormi-medzi-udmi-s-obmedzenymi-nazormi',
    },
    {
      label: 'O mne',
      href: '/o-mne',
    },
    {
      label: 'Kontakt',
      href: '/kontakt',
    },
  ]

  return (
    <FooterContainer id="footer">
      <FooterWrapper>
        <Wrapper>
          <Link href="/">
            <LogoFigure>
              <Image src={logoImage} alt="Ivetars blog logo" />
            </LogoFigure>
          </Link>

          <Flex>
            <Col>
              <h3>Sitemap</h3>
              <ul>
                {sitemap.map((item, idx) => (
                  <li key={idx}>
                    <Link href={item.href}>{item.label}</Link>
                  </li>
                ))}
              </ul>
            </Col>

            <Col>
              <h3>Kategórie</h3>
              <ul>
                {categories.map((category) => (
                  <li key={category.id}>
                    <Link href={`/kategorie/${category.slug}`}>
                      {category.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Col>

            <Col>
              <h3>Sociálne siete</h3>
              <ul>
                {list.map(({ Icon, ...item }, idx) => (
                  <IconLi key={idx}>
                    <Link {...item} href={item.href}>
                      <Icon /> <span>{item.label}</span>
                    </Link>
                  </IconLi>
                ))}
              </ul>
            </Col>
          </Flex>
        </Wrapper>

        <CopyRight>
          © {new Date().getFullYear()} Ivetars. Všetky práva vyhradené.
        </CopyRight>
      </FooterWrapper>
    </FooterContainer>
  )
}

export default Footer

const FooterContainer = styled.footer``

const FooterWrapper = styled.div`
  max-width: 120rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
`

const LogoFigure = styled.figure`
  width: 13rem;

  img {
    width: 100%;
    height: 100%;
  }
`

const CopyRight = styled.p`
  text-align: center;
  margin-top: 5rem;
  opacity: 0.6;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
`

const Col = styled.div`
  h3 {
    margin-bottom: 1rem;
    opacity: 0.6;
    font-weight: 400;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 0.5rem;
  }

  a {
    font-size: 1.4rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.fonts.primary};
    text-decoration: none;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.fonts.secondary};
    }
  }
`

const IconLi = styled.li`
  a {
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.fonts.primary};
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    display: flex;
    align-items: center;
    gap: 1rem;
    span {
      font-size: 1.4rem;
    }

    &:hover {
      color: ${({ theme }) => theme.fonts.secondary};
    }
  }
`

const Flex = styled.div`
  display: flex;
  gap: 4rem;
  flex-wrap: wrap;
`
